import { ppMiscClick, createPlanClick } from '@wix/bi-logger-pricing-plans-data/v2';
import { EditorSDK, EventType } from '@wix/platform-editor-sdk';
import { PerksWidgetRole, PricingWidgetRole } from '@wix/pricing-plans-common/blocks';
import type { ComponentRef, EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { onGlobalDesignPresetChanged } from '../blocks-widgets-editor/globals';
import { onPlanListWidgetAddedToStage } from '../blocks-widgets-editor/plan-list';
import { onSinglePlanWidgetAddedToStage } from '../blocks-widgets-editor/single-plan';
import { onPerkTextStyleChange } from '../components/Benefits/layout-utils';
import { updateCurrencyMargins } from '../components/Pricing/panels/Layout/utils';
import { PrivatePricingPlansApi } from '../types/editor';
import { componentHasRole, getParentWidget, isPlanListWidgetRef, isSinglePlanWidgetRef } from '../utils/widget';

const TOKEN = '';

export async function handleEvents(editorSDK: EditorSDK, flowAPI: EditorScriptFlowAPI) {
  editorSDK.addEventListener(EventType.instanceChanged, (event) => {
    flowAPI.setHttpClientToken(event.detail.instance);
  });

  editorSDK.addEventListener(EventType.anyComponentAddedToStage, (e) =>
    onAnyComponentAddedToStage({ editorSDK, flowAPI, componentRef: e.detail.compRef }),
  );

  editorSDK.addEventListener(EventType.componentStyleChanged, async (e) => {
    onComponentStyleChanged(editorSDK, e.detail.compRef);
  });

  editorSDK.addEventListener(EventType.globalDesignPresetChanged, ({ detail }) => {
    onGlobalDesignPresetChanged({ eventPayload: detail, editorSDK, flowAPI });
  });

  editorSDK.addEventListener(EventType.appActionClicked, async ({ detail }) => {
    onAppActionClicked({ editorSDK, flowAPI, actionId: detail.actionId });
  });
}

async function onAnyComponentAddedToStage(params: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
  flowAPI: EditorScriptFlowAPI;
}) {
  const { editorSDK, componentRef, flowAPI } = params;

  flowAPI.panoramaClient?.errorMonitor().addBreadcrumb({ message: 'PP onAnyComponentAddedToStage' });

  if (await isSinglePlanWidgetRef(editorSDK, componentRef)) {
    onSinglePlanWidgetAddedToStage({ editorSDK, componentRef, flowAPI });
  } else if (await isPlanListWidgetRef(editorSDK, componentRef)) {
    onPlanListWidgetAddedToStage({ editorSDK, componentRef, flowAPI });
  }
}

async function onComponentStyleChanged(editorSDK: EditorSDK, componentRef: ComponentRef) {
  if (await componentHasRole({ role: PerksWidgetRole.PerkText, editorSDK, componentRef })) {
    onPerkTextStyleChange(editorSDK, componentRef);
  } else if (await componentHasRole({ role: PricingWidgetRole.Price, editorSDK, componentRef })) {
    const widgetRef = await getParentWidget({ editorSDK, componentRef });
    if (widgetRef) {
      updateCurrencyMargins({ editorSDK, priceRef: componentRef, widgetRef });
    }
  } else if (await componentHasRole({ role: PricingWidgetRole.Currency, editorSDK, componentRef })) {
    const widgetRef = await getParentWidget({ editorSDK, componentRef });
    if (widgetRef) {
      updateCurrencyMargins({ editorSDK, currencyRef: componentRef, widgetRef });
    }
  }
}

async function onAppActionClicked({
  editorSDK,
  flowAPI,
  actionId,
}: {
  editorSDK: EditorSDK;
  flowAPI: EditorScriptFlowAPI;
  actionId: string;
}) {
  const ppApi: PrivatePricingPlansApi = await editorSDK.editor.getAppAPI();
  flowAPI.bi?.report(
    ppMiscClick({
      referralInfo: 'appAction_' + actionId,
    }),
  );

  if (actionId === 'openManagePlans') {
    await editorSDK.editor.openDashboardPanel(TOKEN, {
      url: '/pricing-plans?referralInfo=manage_app_panel',
      closeOtherPanels: true,
    });
  } else if (actionId === 'createNewPlan') {
    flowAPI.bi?.report(
      createPlanClick({
        appInstanceId: await editorSDK.info.getAppInstanceId(TOKEN),
        biToken: await editorSDK.info.getMetaSiteId(TOKEN),
        formOrigin: 'manage_app_panel',
        referralInfo: 'manage_app_panel',
        origin: 'editor',
      }),
    );
    await editorSDK.editor.openDashboardPanel(TOKEN, {
      url: '/pricing-plans/new?referralInfo=manage_app_panel',
      closeOtherPanels: true,
    });
  } else if (actionId === 'openManagePurchases') {
    await editorSDK.editor.openDashboardPanel(TOKEN, {
      url: '/subscriptions/?referralInfo=manage_app_panel',
      closeOtherPanels: true,
    });
  } else if (actionId === 'openPricingPlansSettings') {
    await ppApi.openSettings();
  } else if (actionId === 'addPricingPlansElements') {
    await ppApi.openAddons(true);
  } else if (actionId === 'openMyAutomations') {
    await editorSDK.editor.openDashboardPanel(TOKEN, {
      url: '/triggers/?referralInfo=manage_app_panel',
      closeOtherPanels: true,
    });
  }
}
