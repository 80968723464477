import { pricingPlansInstallationStage } from '@wix/bi-logger-pricing-plans-data/v2';
import { EditorSDK } from '@wix/platform-editor-sdk';
import type { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import pricingPlans from '../../.application.json';
import { Context } from '../editor.app';
import { createMembersAreaInstallLogger } from '../services/membersAreaInstallLogger';

const TOKEN = '';
const appDefinitionId = pricingPlans.appDefinitionId;

const AUTOPILOT_USER_ID = '6316f5bb-5858-4345-92ed-04566c1d7f54';
const INSTALLATION_TYPE_AUTOPILOT = 'autopilot';
const INSTALLATION_TYPE_REGULAR = 'regular';

const isAutopilotUser = async (editorSDK: EditorSDK) =>
  (await editorSDK.info.getUserInfo(TOKEN)).userId === AUTOPILOT_USER_ID;

export const reportPricingPlansInstallationStage = async ({
  editorSDK,
  stage,
  flowAPI,
}: {
  editorSDK: EditorSDK;
  stage: string;
  flowAPI: EditorScriptFlowAPI;
}) => {
  flowAPI.panoramaClient
    ?.errorMonitor()
    .addBreadcrumb({ message: 'PP reportPricingPlansInstallationStage', data: { stage } });
  const isAutopilot = await isAutopilotUser(editorSDK);
  flowAPI.bi?.report(
    pricingPlansInstallationStage({
      appId: appDefinitionId,
      appInstanceId: await editorSDK.info.getAppInstanceId(TOKEN),
      biToken: await editorSDK.info.getMetaSiteId(TOKEN),
      installationType: isAutopilot ? INSTALLATION_TYPE_AUTOPILOT : INSTALLATION_TYPE_REGULAR,
      stage,
    }),
  );
};

export function maybeCreateMembersAreaInstallLogger(context?: Context) {
  if (!context) {
    return;
  }
  const { flowAPI, editorSDK } = context;
  return createMembersAreaInstallLogger({
    panoramaClient: () => {
      return flowAPI.panoramaClient;
    },
    isAutopilotUser: () => {
      return isAutopilotUser(editorSDK);
    },
    reportPricingPlansInstallationStage: async (stage) => {
      return reportPricingPlansInstallationStage({ editorSDK, stage, flowAPI });
    },
  });
}
