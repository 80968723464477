import { addComponents, EcomComponent, installEcomPlatform } from '@wix/ecom-platform-sdk';
import { EditorSDK } from '@wix/platform-editor-sdk';
import { TPA_EXPERIMENTS } from '@wix/pricing-plans-common/experiments';
import type { EditorReadyFn, EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import pricingPlans from '../../.application.json';
import { EcomServerless } from '../services/ecom-serverless';
import { captureEditorException } from '../utils/editor';
import { installSplitPages } from '../utils/installPages';
import { handleEvents } from './handleEvents';
import { suggestNoComponentsFix, suggestNoMainPageFix, trySetPermissionsPage } from './utils';

const TOKEN = '';
const appDefinitionId = pricingPlans.appDefinitionId;

export const editorReadyInternal: EditorReadyFn = async (editorSDK, token, contextParams, flowAPI) => {
  flowAPI.panoramaClient?.transaction('es_editor_ready').start();
  flowAPI.panoramaClient?.errorMonitor().addBreadcrumb({ message: 'PP editorReadyInternal' });

  const ecomService = new EcomServerless(flowAPI.httpClient);
  const isEcomEnabled = flowAPI.experiments.enabled(TPA_EXPERIMENTS.USE_SERVERLESS_FOR_EIE)
    ? await ecomService.isInPopulation()
    : flowAPI.experiments.enabled(TPA_EXPERIMENTS.ECOM_INTEGRATION_ENABLED);

  try {
    await validateAppComponents({ editorSDK, flowAPI });
  } catch (e) {
    captureEditorException(flowAPI, e, { interactionTag: 'es_editor_ready' });
  }

  try {
    // On first install permission page will be set by appInstalled event handler.
    if (!contextParams.firstInstall) {
      await trySetPermissionsPage(editorSDK, flowAPI);
    }
    if (contextParams.firstInstall && (flowAPI.experiments.enabled(TPA_EXPERIMENTS.INSTALL_ECOM) || isEcomEnabled)) {
      await ecomService.provision();
      await installEcomPlatform(editorSDK);
      await addComponents(editorSDK, [EcomComponent.CHECKOUT, EcomComponent.THANK_YOU_PAGE]);
    }

    await installSplitPages(editorSDK, flowAPI, {
      firstInstall: contextParams.firstInstall,
      withEcom: flowAPI.experiments.enabled(TPA_EXPERIMENTS.INSTALL_ECOM) || isEcomEnabled,
    });

    await handleEvents(editorSDK, flowAPI);

    flowAPI.panoramaClient?.transaction('es_editor_ready').finish();
  } catch (e) {
    captureEditorException(flowAPI, e, { print: true, interactionTag: 'es_editor_ready' });
  }
};

async function validateAppComponents({ editorSDK, flowAPI }: { editorSDK: EditorSDK; flowAPI: EditorScriptFlowAPI }) {
  const components = await editorSDK.document.tpa.app.getAllComps(TOKEN, appDefinitionId);
  const mainPage = components?.find((c) => c.type === 'TPA') ?? null;
  if (components === null) {
    flowAPI.panoramaClient?.errorMonitor().reportError(new Error('There are no Components for app'));
    suggestNoComponentsFix(flowAPI, editorSDK);
  } else if (mainPage === null) {
    flowAPI.panoramaClient?.errorMonitor().reportError(new Error('There is no Main Page Component for app'));
    suggestNoMainPageFix(flowAPI, editorSDK);
  }
}
