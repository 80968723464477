import { GetAppManifestFn, PagesBuilder } from '@wix/yoshi-flow-editor';
import { getPanelUrl } from '@wix/yoshi-flow-editor/utils';
import checkout from '../components/Checkout/.component.json';
import packagePicker from '../components/PackagePicker/.component.json';
import paywall from '../components/Paywall/.component.json';
import planList from '../components/PlanList/.component.json';
import thankYou from '../components/ThankYou/.component.json';

const TOKEN = '';

export function appManifest(setContext: any): GetAppManifestFn {
  return async (options, editorSDK, contextParams, flowAPI) => {
    setContext({
      editorSDK,
      flowAPI,
      contextParams,
    });
    flowAPI.panoramaClient?.errorMonitor().addBreadcrumb({ message: 'PP getAppManifest' });
    const t = flowAPI.translations.t;

    options.appManifestBuilder
      .configureWidget(planList.id, (builder) => {
        builder.set({ displayName: t('manifest.plan-list-widget.display-name') });
      })
      .configureManagementActions((builder) => {
        builder.mainActions().addAction(
          {
            title: t('manage-apps-panel.manage-plans'),
            actionId: 'openManagePlans',
            icon: 'appManager_settingsAction',
          },
          {
            title: t('manage-apps-panel.create-new-plan'),
            actionId: 'createNewPlan',
            icon: 'appManager_addElementsAction',
          },
        );

        builder.customActions().addAction(
          {
            title: t('manage-apps-panel.manage-purchases'),
            actionId: 'openManagePurchases',
            icon: 'appManager_settingsAction',
            type: 'dashboard',
          },
          {
            title: t('manage-apps-panel.customize-plans-page'),
            icon: 'appManager_pagesAction',
            actionId: 'openPricingPlansSettings',
            type: 'editorActions',
          },
          {
            title: t('manage-apps-panel.add-pricing-plans-elements'),
            icon: 'appManager_addElementsAction',
            actionId: 'addPricingPlansElements',
            type: 'editorActions',
          },
          {
            title: t('manage-apps-panel.manage-email-automations'),
            icon: 'email_icon',
            actionId: 'openMyAutomations',
            type: 'dashboard',
          },
        );

        builder.learnMoreAction().set({ id: 'b1d66914-9073-43d1-af55-f923aac621b0' });

        builder.upgradeAction().set({
          upgradeType: 'SITE_UPGRADE',
          upgradeText: t('manage-apps-panel.upgrade-text'),
          upgradeLinkText: t('manage-apps-panel.upgrade-link-text'),
        });
      });

    const managePlansGfpp = {
      label: t('es.gfpp.manage_plans'),
      async onClick() {
        await editorSDK.editor.openDashboardPanel(TOKEN, {
          url: '/pricing-plans?referralInfo=gfpp',
          closeOtherPanels: true,
        });
        return editorSDK.application.livePreview.refresh('', {
          shouldFetchData: true,
          source: 'PRICING_PLANS_PAGE_CLOSED',
        });
      },
    };

    options.appManifestBuilder
      .configureWidget(checkout.id, (builder) =>
        builder
          .gfpp()
          .set('mainAction1', managePlansGfpp)
          .set('mainAction2', { behavior: 'HIDE' })
          .set('settings', { behavior: 'HIDE' }),
      )
      .configureWidget(thankYou.id, (builder) =>
        builder
          .gfpp()
          .set('mainAction1', managePlansGfpp)
          .set('mainAction2', { behavior: 'HIDE' })
          .set('settings', { behavior: 'HIDE' }),
      )
      .configureWidget(paywall.id, (builder) =>
        builder
          .gfpp()
          .set('mainAction1', managePlansGfpp)
          .set('mainAction2', { behavior: 'HIDE' })
          .set('settings', { behavior: 'HIDE' }),
      )
      .configureWidget(planList.id, (builder) => builder.gfpp().set('mainAction1', managePlansGfpp))
      .configureWidget(packagePicker.id, (builder) => builder.gfpp().set('mainAction1', managePlansGfpp))
      .configurePagesTab((pagesTabBuilder) => {
        pagesTabBuilder.set({ displayName: t('es.pages.group.title'), helpId: '' });
        pagesTabBuilder
          .addAction({
            icon: 'settingsAction',
            title: t('es.pages.group.actions.manage'),
            async onClick() {
              await editorSDK.editor.openDashboardPanel(TOKEN, {
                url: '/pricing-plans?referralInfo=site_pages_group_panel',
                closeOtherPanels: true,
              });
              await editorSDK.application.livePreview.refresh('', {
                shouldFetchData: true,
                source: 'PRICING_PLANS_PAGE_CLOSED',
              });
            },
          })
          .addAction({
            icon: 'delete_icon',
            title: t('es.pages.group.actions.delete'),
            onClick() {
              editorSDK.application.uninstall(TOKEN, { openConfirmation: true });
            },
          });
        pagesTabBuilder.addReplaceablePage('membership_plan_picker_tpa');
      })
      .configurePages((pagesBuilder) => {
        pagesBuilder
          .set({ orderIndex: 5, icon: 'page' })
          .addAction({ type: 'page_rename' }, { type: 'page_set_as_homepage' }, { type: 'page_delete' })
          .addSettingsTab(
            { title: t('es.pages.settings-tab.info.title'), type: 'page_info' },
            { title: t('es.pages.settings-tab.layout.title'), type: 'layout' },
            { title: t('es.pages.settings-tab.permissions.title'), type: 'permissions' },
            {
              title: t('es.pages.settings-tab.seo.title'),
              type: 'seo',
              url: getPanelUrl('PackagePicker', 'SeoPanel'),
            },
          );

        const configureInternalPage =
          (
            componentName: 'Checkout' | 'ThankYou' | 'Paywall' | 'PlanCustomization',
            orderIndex: number,
          ): Parameters<PagesBuilder['configureState']>[1] =>
          (stateBuilder) => {
            return stateBuilder
              .set({ orderIndex, icon: 'page' })
              .addAction({ type: 'page_rename' })
              .addSettingsTab(
                {
                  title: t('es.pages.settings-tab.info.title'),
                  type: 'page_info',
                  url: getPanelUrl(componentName, 'PageInfo'),
                },
                { title: t('es.pages.settings-tab.layout.title'), type: 'layout' },
              );
          };

        pagesBuilder
          .configureState('paywall', configureInternalPage('Paywall', 4))
          .configureState('checkout', configureInternalPage('Checkout', 3))
          .configureState('customization', configureInternalPage('PlanCustomization', 3))
          .configureState('ecom_thank_you', configureInternalPage('ThankYou', 2))
          .configureState('thank_you', configureInternalPage('ThankYou', 1));
      });

    return options.appManifestBuilder.build();
  };
}
