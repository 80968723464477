import eventually from 'wix-eventually';
import { WidgetInstallationType, EditorSDK, ComponentRef } from '@wix/platform-editor-sdk';
import { PerksWidgetRole, PlanWidgetRole, SinglePlanPresetId } from '@wix/pricing-plans-common/blocks';
import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import SinglePlanWidget from '../components/SinglePlanWidget/.component.json';
import { SinglePlanInteractions } from '../types/SinglePlanFedops';
import { toError } from '../utils/errors';
import {
  assertSinglePlanWidgetExists,
  setFirstPlanToWidget,
  getContainerRef,
  getPlanWidget,
  getRootWidget,
  updateProgressBar,
  closeProgressBar,
  openProgressBar,
} from '../utils/widget';
import { PLAN_PRESETS, RootPresetId } from './layout/single-plan';

export async function addSinglePlanWidget(params: {
  editorSDK: EditorSDK;
  flowAPI: EditorScriptFlowAPI;
  planId?: string;
}) {
  const { editorSDK, flowAPI, planId } = params;
  const defaultPlanPreset = PLAN_PRESETS[SinglePlanPresetId.vertical];
  flowAPI.panoramaClient?.transaction(SinglePlanInteractions.AddWidget).start();
  const componentRef = await editorSDK.application.appStudioWidgets.addWidget('', {
    widgetId: SinglePlanWidget.id,
    containerRef: await editorSDK.pages.getCurrent(''),
    installationType: WidgetInstallationType.Closed,
    layout: {
      width: defaultPlanPreset.width,
      height: defaultPlanPreset.height,
      x: 0,
      y: 100,
    },
    scopedPresets: {
      desktop: {
        layout: RootPresetId.Desktop,
        style: RootPresetId.Desktop,
      },
    },
    layouts: {
      componentLayout: {
        type: 'ComponentLayout',
        hidden: false,
        height: {
          type: 'auto',
        },
        minHeight: {
          type: 'px',
          value: defaultPlanPreset.height,
        },
        width: {
          type: 'px',
          value: defaultPlanPreset.width,
        },
      },
      itemLayout: {
        id: '',
        alignSelf: 'center',
        justifySelf: 'center',
        type: 'GridItemLayout',
        gridArea: {
          rowStart: 1,
          rowEnd: 2,
          columnStart: 1,
          columnEnd: 2,
        },
        margins: {
          top: {
            type: 'px',
            value: 0,
          },
          left: {
            type: 'px',
            value: 0,
          },
        },
      },
      containerLayout: {
        type: 'GridContainerLayout',
        columns: [{ type: 'fr', value: 1 }],
        rows: [{ type: 'fr', value: 1 }],
      },
    },
  });
  flowAPI.panoramaClient?.transaction(SinglePlanInteractions.AddWidget).finish();

  onSinglePlanWidgetAddedToStage({ editorSDK, componentRef, flowAPI, planId });
}

export async function onSinglePlanWidgetAddedToStage(params: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
  flowAPI: EditorScriptFlowAPI;
  planId?: string;
}) {
  const { editorSDK, componentRef, flowAPI, planId } = params;
  try {
    if (isComponentInitializing(componentRef.id)) {
      return;
    }
    openProgressBar({ editorSDK, title: flowAPI.translations.t('blocks.add-widget-progress-bar.title'), steps: 3 });
    setIsComponentInitializing(componentRef.id);
    flowAPI.panoramaClient?.transaction(SinglePlanInteractions.InitializeInEditor).start();
    /*
    When adding the widget from Add Panel, it takes a bit for it to be
    available through editor SDK. We need to make sure that the component ref
    exists before proceeding with widget initialization
    */
    flowAPI.panoramaClient?.transaction(SinglePlanInteractions.AssertWidgetExists).start();
    await eventually(async () => assertSinglePlanWidgetExists(editorSDK, componentRef), {
      interval: 100,
      timeout: 5000,
    });
    updateProgressBar({ editorSDK, step: 1 });
    flowAPI.panoramaClient?.transaction(SinglePlanInteractions.AssertWidgetExists).finish();

    await initNewSinglePlanWidget({ editorSDK, flowAPI, componentRef, planId });
    flowAPI.panoramaClient?.transaction(SinglePlanInteractions.InitializeInEditor).finish();
    closeProgressBar({ editorSDK });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    editorSDK.components.remove('', { componentRef });
    closeProgressBar({ editorSDK, isError: true });
    flowAPI.panoramaClient?.errorMonitor().reportError(toError(e));
  } finally {
    removeIsComponentInitializing(componentRef.id);
  }
}

async function initNewSinglePlanWidget(params: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
  flowAPI: EditorScriptFlowAPI;
  planId?: string;
}) {
  const { editorSDK, componentRef, flowAPI, planId } = params;
  const planWidget = await getPlanWidget(editorSDK, componentRef);
  const defaultPlanPreset = PLAN_PRESETS[SinglePlanPresetId.vertical];
  const planWidgetParentRef = await getContainerRef(editorSDK, planWidget);

  flowAPI.panoramaClient?.transaction(SinglePlanInteractions.SetInitialPresets).start();
  await editorSDK.application.appStudioWidgets.changePreset('', {
    componentRef,
    layoutPresetId: RootPresetId.Mobile,
    stylePresetId: RootPresetId.Mobile,
    context: {
      viewport: 'MOBILE',
    },
  });

  await editorSDK.application.appStudioWidgets.changePreset('', {
    componentRef: planWidgetParentRef,
    layoutPresetId: defaultPlanPreset.id,
    stylePresetId: defaultPlanPreset.id,
    context: {
      viewport: 'MOBILE',
    },
  });

  await editorSDK.application.appStudioWidgets.changePreset('', {
    componentRef: planWidgetParentRef,
    layoutPresetId: defaultPlanPreset.id,
    stylePresetId: defaultPlanPreset.id,
    context: {
      viewport: 'DESKTOP',
    },
  });
  flowAPI.panoramaClient?.transaction(SinglePlanInteractions.SetInitialPresets).finish();

  flowAPI.panoramaClient?.transaction(SinglePlanInteractions.SetInitialInnerWidgetState).start();
  collapsePerkDivider(editorSDK, componentRef);
  setDefaultRibbonText({ editorSDK, rootWidgetRef: componentRef, flowAPI });
  flowAPI.panoramaClient?.transaction(SinglePlanInteractions.SetInitialInnerWidgetState).finish();

  updateProgressBar({ editorSDK, step: 2 });
  flowAPI.panoramaClient?.transaction(SinglePlanInteractions.SetInitialPlan).start();
  const rootWidget = await getRootWidget(editorSDK, planWidget);
  if (planId) {
    await editorSDK.document.application.appStudioWidgets.props.set('', {
      widgetRef: rootWidget,
      newProps: { planId },
    });
  } else {
    try {
      await setFirstPlanToWidget({
        widgetRef: rootWidget,
        httpClient: flowAPI.httpClient,
        editorSDK,
      });
    } catch (e) {
      flowAPI.panoramaClient?.errorMonitor().reportError(toError(e));
    }
  }
  updateProgressBar({ editorSDK, step: 3 });
  await editorSDK.application.livePreview.refresh('', {
    shouldFetchData: true,
    source: 'CONNECTED_COMPONENT_ADDED',
  });
  flowAPI.panoramaClient?.transaction(SinglePlanInteractions.SetInitialPlan).finish();
}

async function collapsePerkDivider(editorSDK: EditorSDK, rootWidgetRef: ComponentRef) {
  const planComponent = await getPlanWidget(editorSDK, rootWidgetRef);
  const [perksWidget] = await editorSDK.components.findAllByRole('', {
    controllerRef: planComponent,
    role: PlanWidgetRole.PerksWidget,
  });
  const [perkDivider] = await editorSDK.components.findAllByRole('', {
    controllerRef: perksWidget,
    role: PerksWidgetRole.PerkDivider,
  });
  return editorSDK.components.refComponents.collapseReferredComponent('token', {
    componentRef: perkDivider,
  });
}

async function setDefaultRibbonText(params: {
  editorSDK: EditorSDK;
  rootWidgetRef: ComponentRef;
  flowAPI: EditorScriptFlowAPI;
}) {
  const { editorSDK, rootWidgetRef, flowAPI } = params;
  const planWidget = await getPlanWidget(editorSDK, rootWidgetRef);
  const [ribbonWidget] = await editorSDK.components.findAllByRole('', {
    controllerRef: planWidget,
    role: PlanWidgetRole.RibbonWidget,
  });
  await editorSDK.application.appStudioWidgets.props.set('', {
    widgetRef: ribbonWidget,
    newProps: { text: flowAPI.translations.t('blocks.default-badge-text') },
  });
}

const componentInitializingCache = new Set<string>();
function setIsComponentInitializing(id: string) {
  componentInitializingCache.add(id);
}

function isComponentInitializing(id: string) {
  return componentInitializingCache.has(id);
}

function removeIsComponentInitializing(id: string) {
  return componentInitializingCache.delete(id);
}
